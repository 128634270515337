/**
 * External Dependencies
 */
import 'jquery';

// Cookie Consent
/* require('./modules/CookieConsent'); */

// Offset html anchor for fixed header
/* require('./modules/anchor-scroll'); */

// Observer an element
/* require('./modules/observer.js'); */
const menuBtn = document.querySelector('.menu-btn');
const mobileMenu = document.querySelector('.mobile-menu');
const body = document.querySelector('body');

const menuBtnMenu = document.querySelector('.menu-btn-menu');
const menuBtnSchliessen = document.querySelector('.menu-btn-schliessen');
console.log(menuBtnMenu);
console.log(menuBtnSchliessen);

menuBtn.addEventListener('click', () => {
  mobileMenu.classList.toggle('show');
  body.classList.toggle('overflow');
  menuBtnMenu.classList.toggle('hide');
  menuBtnSchliessen.classList.toggle('hide');
});
